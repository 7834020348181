// import Vue from 'vue'

// let mergeArr = [];//合并表格数组
const utils = {
  fromJson: obj => JSON.parse(obj), //字符串转json
  toJson: obj => JSON.stringify(obj), //json转字符串
  regPhone: /^(13|15|18|14|17)[0-9]{9}$/g, //手机号正则匹配
  regEmail: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g,
  regSixCode: /^\d{6}$/g, //六位验证码正则匹配
  regIntegral: /^[0-9]*$/g, //积分数字正则匹配
  //获取url参数
  getQueryArg() {
    const href = location.href;
    if (href.includes("?")) {
      let a = href.split("?");
      const b = a[1];
      //多个参数
      if (b.includes("&")) {
        const c = b.split("&");
        let obj = {};
        c.map(item => {
          const d = item.split("=");
          obj[d[0]] = d[1];
        });
        return obj;
      } else {
        //一个参数
        let obj = {};
        const c = b.split("=");
        obj[c[0]] = c[1];
        return obj;
      }
    }
  },
  //element表单校验手机
  checkTelephone(rule, value, callback) {
    let reg = /^1[34578]\d{9}$/;
    if (!value) {
      return callback(new Error("请输入手机号码"));
    } else if (!reg.test(value)) {
      callback(new Error("请输入正确的11位手机号码"));
    } else {
      callback();
    }
  },
  //函数节流 method为回调执行的方法，wait为执行的时间，_this为了让函数能获取到this的作用域
  throttle: function (method, wait, _this) {
    clearTimeout(method.tId);
    method.tId = setTimeout(function () {
      return method.call(_this);
    }, wait);
  },
  /*计算表格高度
    _this当前vue实例，_this必传
    num 需要减少的高度，默认为0
    waitTime延时执行时间，默认200
    height要设置表格高度的字段名，默认tableHeight
    autoTable表格ref名，默认autoTable
    pageWrap分页ref名，默认pageWrap*/
  getTableHeight(
      _this,
      num = 0,
      waitTime = 200,
      height = "tableHeight",
      autoTable = "autoTable",
      pageWrap = "pageWrap"
  ) {
    _this.$nextTick(() => {
      let timer = null;
      const bodyHeight =
          document.documentElement.offsetHeight || document.body.offsetHeight;
      const tableSetTop = _this.$refs[autoTable] ?
          _this.$refs[autoTable].offsetTop :
          0;
      const pageHeight = _this.$refs[pageWrap] ?
          _this.$refs[pageWrap].offsetHeight :
          0;
      const autoHeight = bodyHeight - tableSetTop - pageHeight - num;
      // console.log(bodyHeight,'bodyHeight')
      // console.log(tableSetTop,'tableSetTop')
      // console.log(pageHeight,autoTable)
      window.addEventListener("resize", () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          utils.getTableHeight(
              _this,
              num,
              waitTime,
              height,
              autoTable,
              pageWrap
          );
        }, waitTime);
      });
      _this.$set(_this.$data, height, autoHeight);
    });
  },
  getDomHeight(
      _this,
      num = 0,
      waitTime = 200,
      parentDom = "mailContent",
      className
  ) {
    _this.$nextTick(() => {
      let timer = null;
      console.log(_this.$refs[parentDom], "parent");
      console.log(
          _this.$refs[parentDom].getElementsByClassName(className)[0],
          "domItem"
      );
      const domItem = _this.$refs[parentDom].getElementsByClassName(
          className
      )[0];
      // console.log(domItem,'domItem')
      const bodyHeight =
          document.documentElement.offsetHeight || document.body.offsetHeight;
      const domTop = domItem ? domItem.offsetTop : 0;
      // const domHeight = document.getElementsByClassName(className)[0].offsetHeight || 0;
      const autoHeight = bodyHeight - domTop - num;
      // console.log(autoHeight,'height')
      // console.log(bodyHeight,'bodyHeight')
      // console.log(domTop,'domTop')
      // console.log(num,'num')
      window.addEventListener("resize", () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          utils.getDomHeight(_this, num, waitTime, parentDom, className);
        }, waitTime);
      });
      domItem.style.height = autoHeight + "px";
    });
  },
  getCustomWidth(_this, waitTime = 200, width = "customWidth", item) {
    _this.$nextTick(() => {
      let timer = null;
      const itemWidth = _this.$refs[item] ? _this.$refs[item].offsetWidth : 0;
      window.addEventListener("resize", () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          utils.getCustomWidth(_this, waitTime, width, item);
        }, waitTime);
      });
      _this.$set(_this.$data, width, itemWidth);
    });
  },
  //合并表格相同的列(rowIndex当前行index, dataList合并的数据，name合并的字段名)
  mergeTableRow(rowIndex, dataList, name) {
    if (dataList.length === 0) {
      return;
    }
    let mergeArr = [],
        mergeIndex = 0;
    dataList.forEach((item, index) => {
      if (index === 0) {
        mergeArr.push(1);
      } else {
        //判断连续相同的列合并
        if (item[name] === dataList[index - 1][name]) {
          mergeArr[mergeIndex] += 1;
          mergeArr.push(0);
        } else {
          mergeArr.push(1);
          mergeIndex = index;
        }
      }
    });
    const _row = mergeArr[rowIndex];
    const _col = _row > 0 ? 1 : 0;
    return {
      rowspan: _row,
      colspan: _col
    };
  },
  //转化附件大小，fileSize（附件大小，以KB为单位，大于1024进行转化），changeSize（要转化单位的大小，默认1024），unit(转化的单位，默认为M)
  filterFileSize(fileSize = 0, changeSize = 1024, unit = "M") {
    let newSize = "";
    if (fileSize > 1024) {
      newSize = (fileSize / changeSize).toFixed(2) + unit;
    } else {
      newSize = fileSize + "KB";
    }
    return newSize;
  },
  // 数组排序
  arrSort(arr, key) {
    return arr.sort((a, b) => {
      return a[key] - b[key];
    });
  },
  // 数组去重
  uniqueArr(arr) {
    return Array.from(new Set(arr));
  },
  // 对象数组去重
  uniqueObjArr(objArr, id = "id") {
    let result = [],
        temp = {};
    for (let i = 0; i < objArr.length; i++) {
      let key = objArr[i][id];
      if (temp[key]) {
        continue;
      }
      temp[key] = true;
      result.push(objArr[i]);
    }
    return result;
  },
  // 获取当前日期
  getCurrentDate(type = "yyyy-MM-dd") {
    let currentDate = "";
    let current = new Date();
    let year = current.getFullYear();
    let month = current.getMonth() + 1;
    let date = current.getDate();
    let hour = current.getHours();
    let minutes = current.getMinutes();
    let seconds = current.getSeconds();
    if (month < 10) {
      month = "-0" + month;
    } else {
      month = "-" + month;
    }
    if (date < 10) {
      date = "-0" + date;
    } else {
      date = "-" + date;
    }
    switch (type) {
      case "yyyy":
        currentDate = year;
        break;
      case "yyyy-MM-dd":
        currentDate = year + month + date;
        break;
      case "yyyy-MM-dd HH:mm":
        currentDate = year + month + date + " " + hour + ":" + minutes;
        break;
      case "yyyy-MM-dd HH:mm:ss":
        currentDate = year + month + date + " " + hour + ":" + minutes + ":" + seconds;
        break;
    }
    return currentDate + "";
  },
  getUa() {
    let _browserType = "";
    let userAgent = window.navigator.userAgent.toLowerCase();
    console.log(userAgent, "userAgent");
    if (userAgent.indexOf("linux") !== -1) {
      return "linux";
    }
    if (userAgent.indexOf("firefox") !== -1) {
      _browserType = "Firefox";
    } else if (
        (userAgent.indexOf("msie") !== -1 || userAgent.indexOf("rv") !== -1) &&
        userAgent.indexOf("trident") !== -1
    ) {
      _browserType = "IE";
    } else if (userAgent.indexOf("wow") < 0 && userAgent.indexOf("edge") < 0) {
      if (
          userAgent.indexOf("safari") !== -1 &&
          userAgent.indexOf("chrome") === -1
      ) {
        _browserType = "Safari";
      } else {
        _browserType = "Chrome";
      }
    } else if (
        userAgent.indexOf("wow") !== -1 &&
        userAgent.indexOf("net") < 0 &&
        userAgent.indexOf("firefox") < 0
    ) {
      _browserType = "360";
    } else {
      _browserType = "other";
    }

    return _browserType;
  },

};

export default utils;
