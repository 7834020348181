<template>
  <div class="yard-wrap">
    <layout-header title="网页后台管理系统" />
    <div class="yard-wrap-main">
      <layout-side :menus="menus" @setCollapse="setCollapse" />
      <div class="yard-content">
        <!-- 测试用菜单 -->
        <div class="yard-content-main">
          <router-view ></router-view>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import layoutHeader from "@/components/layoutCommon/layoutHeader/layoutHeader.vue";
import layoutSide from "@/components/layoutCommon/layoutSide/layoutSide.vue";

export default {
  components: { layoutHeader, layoutSide,  },
  props: {},
  data() {
    return {
      menus: [
        {
          id: "managementPage",
          name: "首页",
          pathName: "managementPage",
          icon: "HomeFilled",
        },
        {
          id: "managementColumnPage",
          name: "栏目管理",
          pathName: "managementColumnPage",
          icon: "HomeFilled",
        },
        {
          id: "managementContentPage",
          name: "内容管理",
          pathName: "managementContentPage",
          icon: "HomeFilled",
        },
        {
          id: "CompanyInforPage",
          name: "公司信息管理",
          pathName: "CompanyInforPage",
          icon: "HomeFilled",
        },


      ],
    };
  },
  watch: {},
  computed: {
    breadcrumbs() {
      let breadcrumbs = [];
      let matched = this.$route.matched; // 获取当前路由的路径对象
      if (matched) {
        matched.forEach((item) => {
          if (item.meta) {
            if (item.meta.parent) {
              breadcrumbs.push({
                path: item.meta.parent.path,
                name: item.meta.parent.name,
              });
            }
            breadcrumbs.push({ name: item.meta.title });
          }
        });
      }
      return breadcrumbs;
    },
  },
  methods: {
    // setCollapse(value) {
      // this.$store.commit("user/setCollapse", value);
    // },
  },
  created() {
    // let nameArr = ["马玲玲", "陈榆", "张淑莹", "管理员", "朱文"];
    // if (nameArr.indexOf(JSON.parse(localStorage.userInfo).displayName) != -1) {
    //   this.menus[3].children.push({
    //     id: "DemoEvaluation",
    //     name: "民主测评",
    //     pathName: "DemoEvaluation",
    //     icon: "UserFilled",
    //   });
    // }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.yard-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .yard-wrap-main {
    display: flex;
    // padding-right: 32px;
    background-color: #f9fbfc;
    flex: 1;
    overflow: hidden;
    .yard-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 48px 16px 16px;
      box-sizing: border-box;
      //overflow: auto;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #fff;
      .el-breadcrumb {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
      }
      .yard-content-main {
        height: 100%;
        // overflow-y: auto;
        background: #fff;
        // display:flex
      }
    }

  }
}

</style>
