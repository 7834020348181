<template>
  <div ref="header" class="layout-header">
    <div class="header-left">
      <img @click="go" src="@/assets/index/logo.png"/>
    </div>
    <ul class="header-right">
      <li v-for="(item,index) in list" :key="item.name" class="header-right-item" >
        <span @click="jump(item,index)"
              :class="active == index ? 'active titleFontSize18' : 'header-text titleFontSize18'">{{ item.name }}</span>
        <div v-show="item.name=='关于我们'" class="pullDown about">
          <div class="pullDown-left">
            <div class="pullDown-left-list">
              <div class="pullDown-item" v-for="(item1,index) in aboutList" :key="item1.name">
                <div class="pullDown-iconlist">
                  <div class="pullDown-icon"></div>
                </div>
                <div @click="goAbout(item1.index,item1.name)" class="pullDown-text titleFontSize16">
                  {{ item1.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="item.name=='解决方案'" class="pullDown solution">
          <div class="pullDown-left">
            <div class="pullDown-left-list">
              <div class="pullDown-item" v-for="(item1,index) in solutionList.slice(0,5)" :key="item1.id">
                <div class="pullDown-iconlist">
                  <div class="pullDown-icon"></div>
                </div>
                <div @click="goSolution(item1)" class="pullDown-text titleFontSize16">
                  {{ item1.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="pullDown-left">
            <div class="pullDown-left-list">
              <div class="pullDown-item" v-for="(item1,index) in solutionList.slice(5,10)" :key="item1.id">
                <div class="pullDown-iconlist">
                  <div class="pullDown-icon"></div>
                </div>
                <div @click="goSolution(item1)" class="pullDown-text titleFontSize16">
                  {{ item1.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="item.name=='新闻中心'" class="pullDown news">
          <div class="pullDown-left">
            <div class="pullDown-left-list">
              <div class="pullDown-item" v-for="(item1,index) in newsTabList" :key="item1.id">
                <div class="pullDown-iconlist">
                  <div class="pullDown-icon"></div>
                </div>
                <div @click="goNews(item1)" class="pullDown-text titleFontSize16">
                  {{ item1.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="item.name=='人才招聘'" class="pullDown joinUs">
          <div class="pullDown-left">
            <div class="pullDown-left-list">
              <div class="pullDown-item" v-for="(item1,index) in joinUsList" :key="item1.name">
                <div class="pullDown-iconlist">
                  <div class="pullDown-icon"></div>
                </div>
                <div @click="goJoinUs(item1.index)" class="pullDown-text titleFontSize16">
                  {{ item1.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>


  </div>
</template>


<script>


export default {
  components: {},
  props: {
    // title: { type: String, default: "" },
    isScroll: {type: Boolean},
  },
  data() {
    return {
      list: [
        {
          "id": 10,
          "name": "首页",
          "pid": 1,
          "sort": 0,
          "content": "index",
          "topPic": "/2023/2/06026c01-ccae-49a0-92c3-b84d0947ba86.jpg,/2023/2/f68a071b-8238-47c4-b68c-224cd54be62a.jpg",
          "topVedio": "/2023/2/6a77fd8d-3e0a-4a3a-8686-7f42fefb24d9.mp4~让治水兴水更加科学智慧1111.mp4,",
          "layout": 3,
          "contentType": 5,
          "deleted": 0
        },
        {
          "id": 2,
          "name": "关于我们",
          "pid": 1,
          "sort": 1,
          "content": "about",
          "topPic": "/2023/3/b5747650-baaf-4f80-bb02-ee4be95b072a.jpg,",
          "topVedio": "",
          "layout": 1,
          "contentType": 1,
          "deleted": 0
        },
        {
          "id": 11,

          "name": "业务领域",
          "pid": 1,
          "sort": 2,
          "content": "products",
          "topPic": "/2023/2/3fa2651b-3c64-497e-88bd-ab64c24433c9.jpg,",
          "topVedio": "",
          "layout": 1,
          "contentType": 2,
          "deleted": 0
        },
        {
          "id": 12,

          "name": "解决方案",
          "pid": 1,
          "sort": 3,
          "content": "solution",
          "topPic": "/2023/2/c61772aa-1379-4052-ac8b-28a418e1e89a.jpg,",
          "topVedio": "",
          "layout": 1,
          "contentType": 1,
          "deleted": 0
        },
        {
          "id": 6,

          "name": "新闻中心",
          "pid": 1,
          "sort": 4,
          "content": "news",
          "topPic": "/2023/2/a5aa6f3e-d06d-4201-9363-45d8845f1aa2.jpg,",
          "topVedio": "",
          "layout": 1,
          "contentType": 1,
          "deleted": 0
        },
        {
          "id": 14,
          "name": "人才招聘",
          "pid": 1,
          "sort": 5,
          "content": "joinUs",
          "topPic": "/2023/2/5f3a9dd1-5eba-4d67-8a8a-043351058b9a.jpg,",
          "topVedio": "",
          "layout": 1,
          "contentType": 1,
          "deleted": 0
        },
        {
          "id": 13,
          "name": "合作伙伴",
          "pid": 1,
          "sort": 6,
          "content": "partner",
          "topPic": "/2023/2/63e092ab-9f1f-40b0-bbb6-c48e4fb87c56.jpg,",
          "topVedio": "",
          "layout": 1,
          "contentType": 1,
          "deleted": 0
        },
        {
          "id": 15,
          "name": "联系我们",
          "pid": 1,
          "sort": 7,
          "content": "contactUs",
          "topPic": "/2023/2/2bfcf395-6222-4757-9058-222357320309.jpg,",
          "topVedio": "",
          "layout": 1,
          "contentType": 1,
          "deleted": 0
        }
      ],
      active: 0,
      isPic: true,
      aboutList: [
          {
            "id": 3,
            "name": "公司简介",
            index:0,
          },
          {
            "id": 21,
            "name": "发展历程",
            index:1,
          },
          {
            "id": 25,
            "name": "组织架构",
            index:2,
          },
          // {
          //   "id": 22,
          //   "name": "荣誉资质",
          //   index:3,
          // },
          {
            "id": 23,
            "name": "服务范围",
            index:3,//修改
          },
          {
            "id": 24,
            "name": "企业文化",
            index:4,//修改
          },
          {
            "id": 4,
            "name": "党建工作",
            index:5,//修改
          }
      ],
      joinUsList: [
        {name: '校园招聘',index:0,},
        {name: '社会招聘',index:1,}
      ],
      solutionList:[],
      newsTabList:[],

    };
  },
  watch: {
    "isScroll": {
      handler: function (newVal, oldVal) {
        console.log(newVal, oldVal);
        if (newVal == true) {
          this.$refs.header.style.background = '#FFFFFF';
        } else {
          this.$refs.header.style.background = 'transparent';
        }
      }
    },
    "active": {
      handler: function (newVal, oldVal) {
        console.log(newVal, oldVal);
        // if (newVal == 0) {
        //   this.getList('首页')
        // } else {
        //   // console.log("444",this.$refs.header.style.position)
        //
        //   // if(this.$refs.header.style.position){
        //   //   this.$refs.header.style.position = 'relative';
        //   // }
        //   // this.$refs.header.style.position = 'relative';
        //   // this.$refs.header.style.background = '#FFFFFF';
        // }
      }
    },
    $route: {
      handler: function (newVal, oldVal) {
        console.log("newVal", newVal, oldVal);
        if (newVal.meta.title != '首页') {
          this.$refs.header.style.position = 'relative';
          this.$refs.header.style.background = '#FFFFFF';
          this.active = 1
        }
        switch (newVal.meta.title) {
          case "首页":
            this.active = 0
            break;
          case "关于我们":
            this.active = 1
            break;
          case "业务领域":
            this.active = 2
            break;
          case "解决方案":
            this.active = 3
            break;
          case "合作伙伴":
            this.active = 6
            break;
          case "新闻中心":
            this.active = 4
            break;
          case "人才招聘":
            this.active = 5
            break;
          case "联系我们":
            this.active = 7
            break;
          case "详情页":
            this.active = null
            break;
          default:
            this.active = null
            break;
        }
      }
    }

  },
  computed: {
    // username() {
    //   const name = JSON.parse(localStorage.userInfo).displayName;
    //   return name;
    // },
  },
  methods: {
    // layout() {
    //   localStorage.clear();
    //   this.$router.push({
    //     path: "/login",
    //   });
    //   // this.$emit("layout");
    // },
    
    //获取列表信息
    getSolutionList() {

      let url1 = this.$appApiUrl.defaultUrl + '/article/listPage?categoryId=' + 12
      this.$appAjax
          .get(url1)
          .then(res => {
            if (res.code == 200) {
              this.solutionList = res.data.records.slice(0,10).map(a=>{
                a.title = a.title.split('解决方案')[0];
                return a;
              });

            }
          });
    },
    getNewsTabList(){
      
      this.$appAjax
          .get(this.$appApiUrl.defaultUrl + "/category/tree", )
          .then((res) => {
            if (res.code === 200) {
              if (res.data.children) {
                this.newsTabList = res.data.children.find(a=>a.id==6).children;

              }

            }
          });
    },
    jump(item, index) {

      this.active = index
      console.log('this.active', this.active)

      sessionStorage.setItem("active", this.active);

      switch (item.name) {
        case "首页":
          this.$router.push(
              {
                path: "/index",
              }
          )
          break;
        case "关于我们":
          this.$router.push(
              {
                path: "/about",
                query: {
                  index: 0
                },
              }
          )
          break;
        case "业务领域":
          this.$router.push(
              {
                path: "/products",
              }
          )
          break;
        case "解决方案":
          this.$router.push(
              {
                path: "/solution",
              }
          )
          break;
        case "合作伙伴":
          this.$router.push(
              {
                path: "/partner",
              }
          )
          break;
        case "新闻中心":
          this.$router.push(
              {
                path: "/news",
              }
          )
          break;
        case "人才招聘":
          this.$router.push(
              {
                path: "/joinUs",
                query: {
                  index: 0
                },
              }
          )
          break;
        case "联系我们":
          this.$router.push(
              {
                path: "/contactUs",
              }
          )
          break;
      }

    },
    init() {
      // let url1 = this.$appApiUrl.defaultUrl + '/site/category-list'
      // this.$appAjax
      //     .post(url1, {
      //       pid: 1
      //     })
      //     .then(res => {
      //       if (res.code == 200) {
      //         console.log('res', res)
      //         this.list = res.data
      //
      //       }
      //     });
      
    this.getSolutionList();
    this.getNewsTabList();
    },
    getList(name) {
      let url1 = this.$appApiUrl.defaultUrl + '/site/category-list'
      this.$appAjax
          .post(url1, {
            name: name
          })
          .then(res => {
            if (res.code == 200) {
              console.log('res', res)
              if (res.data[0].topPic) {
                this.isPic = true
              } else if (res.data[0].topVedio) {
                this.isPic = false
              }
              console.log('this.isPic', this.isPic)
              if (!this.isPic) {
                // this.$refs.header.style.position = 'fixed';
                this.$refs.header.style.background = 'transparent';
              } else {
                this.$refs.header.style.background = '#FFFFFF';
              }
            }
          });
    },
    // getListcategoryId() {
    //   let url1 = this.$appApiUrl.defaultUrl + '/site/category-list'
    //   this.$appAjax
    //       .post(url1, {
    //         name: "关于我们"
    //       })
    //       .then(res => {
    //         if (res.code == 200) {
    //           console.log('res22', res)
    //           this.getList2(res.data[0].id)
    //         }
    //       });
    // },
    // getList2(id) {
    //   let url1 = this.$appApiUrl.defaultUrl + '/site/category-list'
    //   this.$appAjax
    //       .post(url1, {
    //         pid: id
    //       })
    //       .then(res => {
    //         if (res.code == 200) {
    //           console.log(res)
    //           this.aboutList = res.data
    //         }
    //       });
    // },

    goAbout(index,item) {
      console.log(index,'item',item)
      if (item == '党建工作') {
        let html ='https://www.digitwater.com/#/partyBuild'
        window.open(html, '_blank')
        return
      } else {
        this.$router.push(
            {
              path: "/about",
              query: {
                index: index
              },
            }
        )
      }

    },

    goSolution({ categoryId, id }) {
      console.log('解决方案')
      // if(this.$route.path=='/detailPage'){
        this.$router.push(
            {
              path: "/detailPage",
              query: {
                categoryId: categoryId,
                id: id
              },
            }
        )
      // }
      // else{
      //   this.$router.push(
      //       {
      //         path: "/transferPage",
      //         query: {
      //           categoryId: categoryId,
      //           id: id
      //         },
      //       }
      //   )
      // }
      
    },
    goNews({id}){
      this.$router.push(
          {
            path: "/news",
            query: {
              curTab:id
            },
          }
      )
    },
    goJoinUs(index) {
      console.log('人才',index)
      this.$router.push(
          {
            path: "/joinUs",
            query: {
              index: index
            },
          }
      )
    },
    go(){
      // this.$router.push({
      //   path: "/login",
      // });
    }
  },
  created() {
    // if (sessionStorage.getItem("active")) {
    //   this.active = sessionStorage.getItem("active");
    // }
    switch (this.$route.meta.title) {
      case "首页":
        this.active = 0
        break;
      case "关于我们":
        this.active = 1
        break;
      case "业务领域":
        this.active = 2
        break;
      case "解决方案":
        this.active = 3
        break;
      case "合作伙伴":
        this.active = 6
        break;
      case "新闻中心":
        this.active = 4
        break;
      case "人才招聘":
        this.active = 5
        break;
      case "联系我们":
        this.active = 7
        break;
      case "详情页":
        this.active = null
        break;
      default:
        this.active = null
        break;
    }
  },
  mounted() {
    this.init()
    // if (this.active == 0) {
    //   this.getList('首页')
    // } else {
      this.$refs.header.style.position = 'null';
    // }
    // this.getListcategoryId()

    console.log('router', this.$route.meta.title)
  },

};
</script>

<style lang="scss" scoped>
ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.layout-header {
  height: 99px !important;
  width: 100%;
  box-sizing: border-box;
  z-index: 999;
  background: #FFFFFF;
  align-items: center;
  padding: 0 80px;
  .header-left {
    float: left;
    box-sizing: border-box;
    font-weight: bold;
    margin-top: 15px;

    img {
      width: 520px;
      height: 67px;
    }
  }

  .header-right {
    float: right;
    width: 52%;
    line-height: 110px;

    .header-right-item {
      float: left;
      width: 12.5%;
      text-align: center;
      //padding-top: 40px;
      position: relative;
      cursor: pointer;

      .header-icon {
        height: 20px;
        width: 20px;
      }

      .titleFontSize18 {
        font-size: 18px;
      }

      .titleFontSize16 {
        font-size: 16px;
      }

      .header-text {
        //font-size: 1.2vw;
        font-weight: 300;
        line-height: 92px;
        display: inline-block;
        width: 100%;
      }

      .header-btn {
        //color: $color-white;
        //border-color: rgba($color: $color-white, $alpha: 0.7);
      }
    }

    .active {
      width: 100%;
      color: #006EDB;
      //border-top: 8px solid #006EDB;
      //padding-top: 4px;
      font-weight: 500;
      //font-size: 28px;
      line-height: 92px;
      cursor: pointer;
      display: inline-block;
    }

    .header-right-item:hover span.header-text {
      color: #006EDB;
      //border-top: 8px solid #006EDB;
      //padding-top: 4px;
      font-weight: 500;
      display: inline-block;
    }

    .pullDown {
      position: absolute;
      background-color: #FFFFFF;
      width: 160px;
      height: 0;
      overflow: hidden;

      display: flex;
      border-radius: 0 0 8px 8px;
      //background-color: transparent;
      //box-shadow: 0 4px 6px rgba(37, 81, 131, 0.39);
      box-shadow: 0 4px 6px rgba(111, 117, 124, 0.38);
      //display: flex;
      justify-content: space-between;

      .pullDown-left {
        //width: 876px;
        width: 100%;

        .pullDown-left-title {
          width: 100%;
          border-bottom: 1px solid #DBE3EA;
          font-weight: 500;
          font-size: 30px;
          text-align: left;
          padding-bottom: 20px;
          box-sizing: border-box;
          line-height: 26px;
        }

        .pullDown-left-list {
          //display: flex;
          width: 100%;
          flex-wrap: wrap;
          //width: 606px;
          margin-top: 10px;
          padding-left: 20px;
          box-sizing: border-box;

          .pullDown-item {
            display: flex;
            //justify-content: space-between;
            align-content: center;
            //margin-right: 148px;

            .pullDown-iconlist {
              display: flex;
              flex-direction: column;
              //flex-direction: column;
              justify-content: center;

            }

            .pullDown-icon {
              width: 10px;
              height: 10px;
              background-color: #D9D9D9;
              border-radius: 50%;
            }

            .pullDown-text {
              font-weight: 300;
              //font-size: 1.2vw;
              text-align: left;
              line-height: 48px;
              cursor: pointer;
              margin-left: 18px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              max-width: 260px;
            }

            .titleFontSize16 {
              font-size: 16px;
            }

            //.pullDown-item:hover {
            //  .pullDown-iconlist .pullDown-icon{
            //    background-color: #006EDB
            //  }
            //
            //}
            &:hover{
              .pullDown-icon{
               background-color: #006EDB;
              }
              .pullDown-text {
                color: #006EDB;
              }
            }
          }
        }
      }

      .pullDown-right {
        img {
          width: 290px;
          height: 190px;
        }
      }
    }

    .solution{
      top: 99px;
      left: -20px;
      width: 650px;
    }

    .news{
      top: 99px;
      left: -20px;
    }

    .about {
      top: 99px;
      left: -20px;
    }

    .joinUs {
      top: 99px;
      left: -20px;
    }

    .header-right-item:hover .solution {
      display: flex;
      height: 270px;
      //padding: 40px 60px;
      box-sizing: border-box;
      transition: all 0.6s;
    }

    .header-right-item:hover .news {
      display: flex;
      height: 170px;
      //padding: 40px 60px;
      box-sizing: border-box;
      transition: all 0.6s;
    }
    .header-right-item:hover .about {
      display: flex;
      height: 300px;//修改
      //padding: 40px 60px;
      box-sizing: border-box;
      transition: all 0.6s;
    }

    .header-right-item:hover .joinUs {
      display: flex;
      height: 120px;
      //padding: 40px 60px;
      box-sizing: border-box;
      transition: all 0.6s;
    }
  }
}
</style>
