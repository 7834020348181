<template>
  <div class="layout-bottom">
    <div class="bottom-top">
      <div class="bottom-top-left">
        <div class="bottom-left-title titleFontSize14 ">
          全国服务热线
        </div>
        <div class="bottom-left-text1 titleFontSize30">
          400-990-8370
        </div>
        <div class="bottom-left-text2 titleFontSize14">
          总部地址：<span>{{ contactUs.address }}</span>
        </div>
        <div class="bottom-left-text2 titleFontSize14">
          电话：<span>020-87117245</span>
        </div>
        <div class="bottom-left-text2 titleFontSize14">
          传真：<span>{{ contactUs.fax }}</span>
        </div>
      </div>
      <div class="bottom-top-middle">
        <div class="bottom-middle-item">
          <div class="middle-item-title titleFontSize14">
            关于我们
          </div>

          <div class="middle-item-text titleFontSize14" @click="jump('about',item1.index)" v-for="item1 in item1List" :key="item1.title">
            {{ item1.title }}
          </div>

        </div>
        <div class="bottom-middle-item">
          <div class="middle-item-title titleFontSize14">
            业务领域
          </div>
          <div class="middle-item-text titleFontSize14" @click="goProducts" v-for="item1 in item1List2" :key="item1.title">
            {{ item1.title }}
          </div>
        </div>
        <div class="bottom-middle-item">
          <div class="middle-item-title titleFontSize14">
            解决方案
          </div>
          <div @click="goSolution(item1)" class="middle-item-text titleFontSize14" v-for="item1 in item1List3" :key="item1.title">
            {{ item1.title }}
          </div>
        </div>
        <div class="bottom-middle-item">
          <div class="middle-item-title titleFontSize14">
            新闻中心
          </div>
          <div @click="goNews(item1)" class="middle-item-text titleFontSize14" v-for="item1 in item1List4" :key="item1.name">
            {{ item1.name }}
          </div>
        </div>
        <div class="bottom-middle-item">
          <div class="middle-item-title titleFontSize14">
            人才招聘
          </div>
          <div class="middle-item-text titleFontSize14" @click="jump('joinUs',item1.index)" v-for="item1 in item1List5" :key="item1.title">
            {{ item1.title }}
          </div>
        </div>
      </div>
      <div class="bottom-top-right">
        <img :src="contactUsPic" alt="">
        <div class="bottom-right-text1 titleFontSize18">
          关注华南高新
        </div>

      </div>
    </div>
    <div class="bottom-bottom">
      版权所有 @ 广东华南水电高新技术开发有限公司
    </div>
    <div class="bottom-bottom1" @click="records">
      备案号: 粤ICP备11063237号-1
    </div>

  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {type: String, default: ""},

  },
  data() {
    return {
      contactUs: [],
      contactUsPic: null,
      item1List: [
        {title: '公司简介',index:0},
        {title: '发展历程',index:1},
        {title: '组织架构',index:2},
        {title: '荣誉资质',index:3},
        {title: '服务范围',index:4},
        {title: '企业文化',index:5},
        {title: '党建工作',index:6},
      ],
      item1List2: [
        {title: '智慧水利'},
        {title: '水利工程建设运行管理'},
        {title: '河湖动态监管'},
        {title: '水旱灾害防御'},
        {title: '水资源管理'},
        {title: '...'},
      ],
      item1List3: [
        {title: '智慧水利建设'},
        {title: '水利工程动态监管'},
        {title: '水旱灾害防御体系标准化'},
        {title: '智能识别视频监控终端'},
        {title: '水土保持监管'},
        {title: '...'},
      ],
      item1List4: [
        {title: '公司新闻'},
        {title: '水利要闻'},
        {title: '公示公告'},

      ],
      item1List5: [
        {title: '校园招聘',index:0},
        {title: '社会招聘',index:1},

      ],
    };
  },
  watch: {},
  computed: {
    // username() {
    //   const name = JSON.parse(localStorage.userInfo).displayName;
    //   return name;
    // },
  },
  methods: {
    //获取列表信息
    getSolutionList() {

      let url1 = this.$appApiUrl.defaultUrl + '/article/listPage?categoryId=' + 12
      this.$appAjax
          .get(url1)
          .then(res => {
            if (res.code == 200) {
              this.item1List3 = res.data.records.slice(0,5).map(a=>{
                a.title = a.title.split("解决方案")[0];
                return a;
              });
              this.item1List3.push({title: '...'})

            }
          });
    },
    getNewsTabList(){
      
      this.$appAjax
          .get(this.$appApiUrl.defaultUrl + "/category/tree", )
          .then((res) => {
            if (res.code === 200) {
              if (res.data.children) {
                this.item1List4 = res.data.children.find(a=>a.id==6).children;

              }

            }
          });
    },
    goSolution({ categoryId, id }) {
      console.log('解决方案')
      if(!id) {
        this.$router.push({
          path:"/solution"
        })
        return
      };
      this.$router.push(
          {
            path: "/detailPage",
            query: {
              categoryId: categoryId,
              id: id
            },
          }
      )
    },
    goNews({id}){
      this.$router.push(
          {
            path: "/news",
            query: {
              curTab:id
            },
          }
      )
    },
    goProducts(){
      this.$router.push(
          {
            path: "/Products",
          }
      )
    },
    records(){
      window.open("https://beian.miit.gov.cn/")
    },
    init() {
      this.getSolutionList();
      this.getNewsTabList();
      let url1 = this.$appApiUrl.defaultUrl + '/company-info/findOne'
      this.$appAjax
          .get(url1)
          .then(res => {
            if (res.code == 200) {
              this.contactUs = res.data
              this.contactUsPic = this.$appApiUrl.defaultUrl + '/attach/down?filePath=' + res.data.codePath
              console.log('contactUsPic', this.contactUsPic)
            }
          });
    },
    jump(params,index) {
      console.log('index',index)
      if (index == 6 && params=='about') {
        let html = this.$appApiUrl.defaultUrl + '/html/#/partyBuild'
        window.open(html, '_blank')
        return
      } else {
        this.$router.push(
            {
              path: "/"+params,
              query: {
                index: index
              },
            }
        )
      }
    },

  },
  created() {
  },
  mounted() {
    this.init()
  },
};
</script>

<style lang="scss" scoped>

.layout-bottom {
  padding: 30px 200px 40px 200px;
  box-sizing: border-box;
  height: 390px;
  //background-color: #1A253C;

  background-color: #F3F3F3;
  //background-image: url("../../../assets/index/bg3.png");
  //background-size:cover;
  .bottom-top {
    display: flex;
    justify-content: space-between;

    .bottom-top-left {
      .bottom-left-title {
        font-weight: 400;
        text-align: left;
        color: #333333;
        margin-bottom: 10px;
      }

      .bottom-left-text1 {
        color: #333333;
        font-weight: 700;
        margin: 10px 0 26px 0;

      }

      .bottom-left-text2 {
        color: #999999;
        text-align: left;
        line-height: 30px;
      }

    }

    .bottom-top-middle {
      width: 880px;
      display: flex;
      justify-content: space-between;

      .bottom-middle-item {

        width: 25%;
        .middle-item-title {

          color: #333333;
          margin-bottom: 15px;
        }

        .middle-item-text {
          color: #999999;
          width: 80%;
          margin-bottom: 15px;
          cursor: pointer;
          &:hover {
            color: #006EDB;
          }
        }


      }
    }

    .bottom-top-right {
      img {
        width: 132px;
        height: 132px;
      }

      .bottom-right-text1 {

        color: #333333;
        font-weight: 300;
        text-align: center;
        line-height: 40px;

      }

    }
  }
  .bottom-bottom {
    border-top: 1px solid #E3E3E3;
    color: #999999;
    padding-top: 20px;
  }
  .bottom-bottom1 {
    //border-top: 1px solid #E3E3E3;
    color: #999999;
    padding-top: 20px;
    cursor: pointer;
  }

  .titleFontSize14 {
    font-size: 14px !important;
  }

  .titleFontSize30 {
    font-size: 30px !important;
  }

  .titleFontSize18 {
    font-size: 18px !important;
  }
}
</style>
