import appAjax from "../utils/apiRequestType";

export default {
  install(app) {
    app.config.globalProperties.$appAjax = appAjax;
    app.appAjax = appAjax;
  },
  $appAjax: appAjax
};
export const $appAjax = appAjax;
