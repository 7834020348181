import { createApp, createVNode } from 'vue'
import App from "./App.vue";
// import router from "./router/index";
import authFun from "./config/auth";
//ajax请求
import appAjax from "./appPlugins/appApiRequest";
//apiUrl地址
import appApiUrl from "./appPlugins/appApiUrl";
//全站工具类包括了正则验证、页面title、json转字符串、字符串转json
import appUtils from "./appPlugins/appUtils";

//element-ui
// import ElementPlus from 'element-plus'

// 自定义element ui主题
import './style/theme/index.scss'
// 引入全局样式
import "./style/index.scss";
import "./style/common/formStyle.scss";
// 中文模块
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// 动态icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import pinia from './store'

import router from "./router";
import "./router/permission";

import * as echarts from "echarts";
// import 'default-passive-events'



import {
    ElButton,
    ElForm ,
    ElFormItem,
    ElInput,
    ElTabs,
    ElTabPane,
    ElIcon,
    ElTable,
    ElTableColumn,
    ElCascader,
    ElPagination,
    ElNotification,
    ElMessage,
    ElTooltip,
    ElMenu,
    ElMenuItem,
    ElSubMenu,
    ElDatePicker,
    ElProgress,
    ElRadioGroup,
    ElRadio,
    ElTree,
    ElOption,
    ElSelect,
    ElUpload,
    ElDialog,
    ElMessageBox,
    ElLoadingService,
    ElCarousel,
    ElCarouselItem
} from "element-plus";


const app = createApp(App);

app.config.productionTip = false;
//创建全局实例
app.use(ElTree);
app.use(ElRadio);
app.use(ElProgress);
app.use(ElRadioGroup);
app.use(ElDatePicker);
app.use(ElSubMenu);
app.use(ElMenuItem);
app.use(ElMenu);
app.use(ElTooltip);
app.use(ElOption);
app.use(ElSelect);
app.use(ElUpload);
app.use(ElDialog);
app.use(ElDialog);
app.use(ElMessageBox);
app.use(ElMessage);
app.use(ElLoadingService);
app.use(ElNotification);
app.use(ElButton);
app.use(ElForm);
app.use(ElFormItem);
app.use(ElInput);
app.use(ElIcon);
app.use(ElTable);
app.use(ElTableColumn);
app.use(ElCascader);
app.use(ElPagination);
app.use(ElTabPane);
app.use(ElCarousel);
app.use(ElCarouselItem);
app.use(ElTabs);
app.use(pinia);
app.use(router);
app.use(appAjax);
app.use(appApiUrl);
app.use(appUtils);


// app.use(ElementPlus, { locale: zhCn });


app.mount("#app");


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

Date.prototype.Format = function (fmt) {
    const o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        'S': this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
    return fmt
}

export default app;