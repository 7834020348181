<template>
  <div class="layout-header">
    <div class="header-left">
      <img src="@/assets/head/logo.png" />{{ title }}
    </div>
    <ul class="header-right">

      <li class="header-right-item" @click="layout">

        <img src="@/assets/head/out.png" class="header-icon" />
        <span class="header-text">退出</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: { type: String, default: "" },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    // username() {
    //   const name = JSON.parse(localStorage.userInfo).displayName;
    //   return name;
    // },
  },
  methods: {
    layout() {
      localStorage.clear();
      this.$router.push({
        path: "/login",
      });
      // this.$emit("layout");
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
ul,
ol,
li {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.layout-header {
  height: 60px !important;
  background: linear-gradient(
    270deg,
    rgba(19, 87, 222, 100),
    rgba(69, 162, 248, 100)
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  padding-right: 35px;
  color: $color-white;
  padding: 0 36px;
  .header-left {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: bold;
  }
  .header-right {
    display: flex;
    font-size: 14px;
    .header-right-item {
      display: flex;
      align-items: center;
      margin-left: 30px;
      cursor: pointer;
      .header-icon {
        height: 20px;
        width: 20px;
      }
      .header-text {
        margin-left: 8px;
      }
      .header-btn {
        color: $color-white;
        border-color: rgba($color: $color-white, $alpha: 0.7);
      }
    }
  }
}
</style>
