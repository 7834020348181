//apiUrl插件注入到Vue这个对象
import appApiUrl from "../utils/apiUrl";

export default {
  install(app) {
    app.config.globalProperties.$appApiUrl = appApiUrl;
    app.appApiUrl = appApiUrl;
  },
  $appApiUrl: appApiUrl
};

export const $appApiUrl = appApiUrl;
