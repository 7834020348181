<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <router-view/>
    </el-config-provider>
  </div>
</template>

<script>
//element UI改中午
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";

export default {
  name: "ZhProvider",
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  setup() {
    let locale = zhCn;
    return {
      locale,
    };
  },
}
</script>
<style lang="scss">

</style>