import utils from "../utils/utils";

export default {
  install(app) {
    app.config.globalProperties.$appUtils = utils;
    app.appUtils = utils;
  },
  $appUtils: utils
};
export const $appUtils = utils;
