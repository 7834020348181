import router from "./index";
import authFun from "../config/auth";

router.beforeEach((to, from, next) => {
    console.log('222')
    if(to.meta.requireAuth){
        authFun(to, from, next);
    }
    next();

});
