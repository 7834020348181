<template>
  <div>
    <template v-for="item in menus">
      <el-menu-item
        v-if="validateNull(item[childrenKey])"
        :index="item[indexKey]"
        :key="item[indexKey]"
        @click="open(item)"
        ><el-icon>
          <component
            v-if="item[iconKey]"
            style="width:16px;height:16px"
            :is="item[iconKey]"
          />
        </el-icon>
        <template #title>
          <span>
            <img
              v-if="item[urlKey]"
              :src="require('../../../' + item[urlKey] + '.png')"
              style="margin-right: 6px;"
            />
            <span>{{ item[labelKey] }}</span>
          </span>
        </template>
      </el-menu-item>
      <el-sub-menu
        v-else-if="!validateNull(item[childrenKey])"
        :index="item[indexKey]"
        :key="item[indexKey]"
      >
        <template #title>
          <el-icon>
            <component
              v-if="item[iconKey]"
              style="width:16px;height:16px"
              :is="item[iconKey]"
            />
          </el-icon>
          <img
            v-if="item[urlKey]"
            :src="require('../../../' + item[urlKey] + '.png')"
            style="margin-right: 6px;"
          />
          <span>{{ item[labelKey] }}</span>
        </template>
        <side-menu :menus="item[childrenKey]" :props="props"></side-menu>
      </el-sub-menu>
    </template>
  </div>
</template>
<script>
export default {
  name: "SideMenu",
  data() {
    return {};
  },
  props: {
    menus: { type: Array, default: () => [] },
    props: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    // 显示菜单名
    labelKey() {
      return this.props.label || "name";
    },
    // 菜单唯一标识
    indexKey() {
      return this.props.index || "id";
    },
    // 图标地址(自定义)
    urlKey() {
      return this.props.url || "url";
    },
    // 图标地址(element-ui)
    iconKey() {
      return this.props.icon || "icon";
    },
    // 子菜单字段名
    childrenKey() {
      return this.props.children || "children";
    },
    // 路由名称
    pathName() {
      return this.props.pathName || "pathName";
    },
    // 路由地址
    pathUrl() {
      return this.props.pathUrl || "pathUrl";
    },
  },
  methods: {
    validateNull(val) {
      if (
        val == "null" ||
        val == null ||
        val == "undefined" ||
        val == undefined ||
        val == ""
      ) {
        return true;
      } else if (val instanceof Array && val.length == 0) {
        return true;
      } else if (val instanceof Object && JSON.stringify(val) === "{}") {
        return true;
      }
      return false;
    },
    open(item) {
      if (item[this.pathName] && item[this.pathName] !== this.$route.name) {
        this.$router.push({
          name: item[this.pathName],
        });
      } else if (
        item[this.pathUrl] &&
        item[this.pathUrl] !== this.$route.path
      ) {
        this.$router.push({
          path: item[this.pathUrl],
        });
      }
    },
  },
};
</script>
<style>
.el-menu--collapse .el-submenu__title span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}






.el-menu-item span {
word-break:break-all;
  text-overflow: clip;
  line-height:40px;
  
  flex: 1;
}
</style>
