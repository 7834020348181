<template>
  <div class="yard-wrap" style="min-width:940px;">
<!--    <div>主页layout</div>-->
    <layout-header2  :isScroll="isScroll"/>
    <div class="yard-wrap-main">
<!--      <layout-side :menus="menus" @setCollapse="setCollapse" />-->
      <div class="yard-content" ref="personDom">
        <div class="yard-content-main">
          <router-view ></router-view>
        </div>
      </div>
    </div>
    <div v-show="showFloat">
      <floating-layer></floating-layer>
    </div>

  </div>
</template>

<script>

import layoutHeader2 from "@/components/layoutCommon/layoutHeader2/layoutHeader2.vue";
import layoutBottom from "@/components/layoutCommon/layoutBottom/layoutBottom.vue";
import layoutSide from "@/components/layoutCommon/layoutSide/layoutSide.vue";
import floatingLayer from "@/layout/components/floatingLayer/Index.vue";
import { WOW } from 'wowjs'
export default {
  components: {layoutHeader2, layoutSide,layoutBottom,floatingLayer},
  props: {},
  data() {
    return {
      isScroll:false,
      showFloat:false,
    };
  },
  watch: {
    $route(){
      this.$refs.personDom.scrollTop=0

    }
  },
  computed: {
    breadcrumbs() {
      let breadcrumbs = [];
      let matched = this.$route.matched; // 获取当前路由的路径对象
      if (matched) {
        matched.forEach((item) => {
          if (item.meta) {
            if (item.meta.parent) {
              breadcrumbs.push({
                path: item.meta.parent.path,
                name: item.meta.parent.name,
              });
            }
            breadcrumbs.push({ name: item.meta.title });
          }
        });
      }
      return breadcrumbs;
    },
  },
  methods: {

    setCollapse(value) {
      this.$store.commit("user/setCollapse", value);
    },


    handleScroll(){
      let scrollTop = this.$refs.personDom.scrollTop; //滑入屏幕上方的高度
      let windowHeitht = this.$refs.personDom.clientHeight; //能看到的页面的高度
      let scrollHeight = this.$refs.personDom.scrollHeight; //监控的整个div的高度（包括现在看到的和上下隐藏起来看不到的）
      let total = scrollTop + windowHeitht
      // console.log('scrollTop',scrollTop)

      if(scrollTop>0){
        this.isScroll=true
      }else{
        this.isScroll=false
      }
      if(scrollTop>=50){
        this.showFloat=true
      }else{
        this.showFloat=false
      }
      if(scrollTop>1800){
        return 0
      }

    },
    goTop(){

      const timeTop = setInterval(() => {
          if (this.$refs.personDom.scrollTop > 0) {
            this.$refs.personDom.scrollTop -= 100 // 不在顶部 每次滚动到的位置
          } else {
            clearInterval(timeTop) // 回到顶部清除定时器
          }

      }, 10)
    },
  },

  created() {

  },

  mounted() {
    this.$refs.personDom.addEventListener('scroll',this.handleScroll)
    this.$refs.personDom.scrollTop=0
    console.log('this.$refs.regards',this.$refs.regards)

    // this.$nextTick(() => {
    //   let wow = new WOW({
    //     boxClass: "wow", //需要执行动画元素的Class
    //     animateClass: "animated", //animation.css动画的Class
    //     offset: 500, //距离可视区域多少开始执行动画
    //     mobile: true, //是否在移动设备执行动画
    //     live: false, //异步加载的内容是否有效
    //     callback: function (box) {
    //       console.log("WOW: animating <")
    //     },
    //     scrollContainer:'.yard-content'
    //   });
    //   wow.init();
    // });
  },
};
</script>

<style lang="scss" scoped>
.yard-wrap {
  height: 100%;
  color: #333333;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .yard-wrap-main {
    position: relative;
    display: flex;
    // padding-right: 32px;
    background-color: #f9fbfc;
    flex: 1;
    overflow: hidden;
    .yard-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      //padding: 0 48px 16px 16px;
      box-sizing: border-box;
      //overflow: auto;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #fff;
      .el-breadcrumb {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
      }
      .yard-content-main {
        //height: 100%;
        // overflow-y: auto;
        background: #fff;
        // display:flex
      }
    }
  }
}
</style>
