import AjaxPlugin from "axios";
import {
  ElLoading
} from "element-plus";

let loadingInstance = null;
const loadingOption = {
  lock: false,
  // text: "加载中..."
};

export default function ajaxInterceptors() {
  //添加一个api请求拦截器
  AjaxPlugin.interceptors.request.use(
    config => {
      //添加loding中组件
      //设置isHide不需要显示loading
      // loadingInstance = Loading.service(loadingOption);

      if (config.params) {
        if (config.params.isHide) {
          loadingInstance.close();
        } else {

          loadingInstance = ElLoading.service(loadingOption);
        }
      } else {

        loadingInstance = ElLoading.service(loadingOption);
      }

      if (config.method === "get" && config.url.indexOf("stamp") === -1) {
        const stamp = new Date().getTime();
        if (config.url.indexOf("?") !== -1) {
          config.url += `&stamp=${stamp}`;
        } else {
          config.url += `?stamp=${stamp}`;
        }
      }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  //添加一个响应拦截器
  AjaxPlugin.interceptors.response.use(
    function (response) {
      loadingInstance.close();
      return response;
    },
    function (error) {
      loadingInstance.close();
      return Promise.resolve(error.response);
    }
  );
}