<template>
  <div class="layout-side">
    <el-tooltip
      v-if="showCollapse"
      :value="!isCollapse"
      :disabled="!isCollapse"
      effect="dark"
      placement="right"
      content="展开菜单"
    >
      <div class="collapse-btn" @click="setCollapse">
        <span v-if="!isCollapse">收起菜单</span>
        <img
          :src="
            require(`@/assets/${isCollapse ? 'collapse' : 'expand'}-icon.png`)
          "
        />
      </div>
    </el-tooltip>
    <div class="layout-side-menus">
      <div v-if="!menus || !menus.length" class="sidebar--tip">
        没有发现菜单
      </div>
      <el-menu
        class="leftMenu"
        mode="vertical"
        :default-active="activeMenu"
        :collapse="isCollapse"
        :class="{ 'menu-collapse': !isCollapse }"
        background-color="rgba(245,248,253,1)"
      >
        <side-menu :menus="menus" :props="props" />
      </el-menu>
    </div>
  </div>
</template>
<script>
import SideMenu from "./SideMenu.vue";
export default {
  name: "layoutSide",
  components: { SideMenu },
  props: {
    showCollapse: { type: Boolean, default: true },
    menus: { type: Array, default: () => [] },
    props: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isCollapse: false,
    };
  },
  computed: {
    activeMenu() {
      console.log(this.$route.name);
      return this.$route.name;
    },
  },
  methods: {
    setCollapse() {
      this.isCollapse = !this.isCollapse;
      this.$emit("setCollapse", this.isCollapse);
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-side {
  height: 100%;
  overflow: hidden;
  background-color: rgba(240, 245, 252, 1);
  display: flex;
  flex-direction: column;
  .collapse-btn {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
  }
  .el-menu {
    border: 0;
    &.menu-collapse {
      width: 200px;
    }
  }

  .layout-side-menus {
    flex: auto;
    overflow: auto;
  }

  .sidebar--tip {
    text-align: center;
    margin-top: 10px;
  }
}
</style>
<style lang="scss">
.leftMenu {
  // background-color: rgba(240, 245, 252, 1) !important;
  .el-menu {
    // background-color: rgba(240, 245, 252, 1) !important;
  }
  .el-menu-item {
    span {
      width: 90px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .el-sub-menu__title {
    margin-left: 16px;
    padding-left: 0 !important;
    > span {
      width: 90px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .el-menu-item,
  .el-menu-item > span,
  .el-sub-menu__title,
  .el-sub-menu__title > span {
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
  .el-menu-item:hover {
    background: rgba(226, 233, 248, 1);
  }
}
.collapse-btn {
  font-weight: 600;
}
</style>
