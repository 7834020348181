<!--悬浮-->
<template>
  <div class="bombLayer">
    <div class="list">
      <div class="item" @click="goSearch">
        <img class="item-icon" src="../../../assets/index/search.png" alt="">
      </div>
      <div class="item" @click="goContactUs">
        <img class="item-icon" src="../../../assets/index/phone.png" alt="">
        <div class="phoneNum">400-990-8370</div>
      </div>
      <div class="item QRCode1" >
        <img class="item-icon " src="../../../assets/index/weixin.png" alt="">
          <div  class="QRCode2">
            <img  src="../../../assets/index/qrCode.png" alt="">
          </div>
      </div>
      <div class="item" @click="goTop1">
        <img class="item-icon" src="../../../assets/index/top.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>

// import userLayerStroe from "../../../store/module/indexLayer";
// import { storeToRefs } from "pinia";
// const LayerStroe = userLayerStroe();
// const { openFlag } = storeToRefs(LayerStroe);
export default {
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      openFlag: false, //是否展开
      TopActive: "Contract",
      icon:false
    };
  },
  watch: {
    $route() {
      this.openFlag = false;
    },
  },
  computed: {},
  methods: {
    goTop1() {
      this.$parent.goTop()
    },
    goSearch() {
      this.$router.push(
          {
            path: "/searchPage",
            query: {},
          }
      )
    },
    goContactUs() {
      this.$router.push(
          {
            path: "/contactUs",
            query: {},
          }
      )
    },
    showIcon(){
      console.log(1)
    }
  },
  created() {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
