// import qs from 'qs'
import ajaxInterceptors from "./ajaxInterceptors";
import AjaxPlugin from "axios";
// import qs from "qs";
import { Message } from "element-plus";

// import source from "./requestCancel";
//检测请求状态
function checkStatus(response) {
  // console.log(response, 'resp')
  try {
    // 如果 http 状态码正常, 则直接返回数据
    if (response.status === 200 || response.status === 304) {
      return response.data;
    }
    // 异常状态下, 把错误信息返回去
    // 因为前面我们把错误扶正了, 不然像 404, 500 这样的错误是走不到这里的
    return {
      data: {
        code: response.status,
        errorMsg: response.statusText,
        data: response.data
      }
    };
  } catch (error) {
    return {
      data: {
        errorMessage: "网络异常"
      }
    };
  }
}

//检测状态码
function checkCode(res) {
  // 如果状态 code 异常(这里已经包括网络错误, 服务器错误, 后端抛出的错误), 可以弹出一个错误提示, 告诉用户
  if (res.code && res.code == 200) {
    return res;
  } else {
    let errorText;
    if(res.data){
      errorText = res.data.errorMsg || res.data.errorMessage || res.data;
    }
    if (res.data && res.data.code) {
      if (res.data.code == 500) {
        Message.error("500，服务器网络异常，请联系管理员");
      } else if (res.data.code == 404) {
        Message.error("404，服务器服务异常，请联系管理员");
      }
    } else {
      if (!res.code) {
        Message.error(errorText);
      }else{
        return res;
      }
    }
    // if (res.status) {
    //   if (res.status === 4) {
    //     //表单内容错误
    //     let errorText = res.data;
    //     Message.error(errorText);
    //   }
    //   return res;
    // } else {
    //   return res;
    // }
  }
}
//get or post请求封装
export default {
  get(url, params, responseType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";

    ajaxInterceptors();
    const option = {
      method: "get",
      url: url,
      params, // get 请求时带的参数
      timeout: 120000,
      withCredentials: true,
      headers: {
        "website-token": accessToken,
        "Cache-Control": "no-cache",
        Pragma: "no-cache"
      }
    };
    if (responseType) {
      option.responseType = responseType;
    }
    return AjaxPlugin(option)
      .then(checkStatus)
      .then(checkCode);
  },
  post(url, data, contentType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "post", // 请求协议
      url: url, // 请求的地址
      // data: qs.stringify(data),
      data: JSON.stringify(data), // post 请求的数据
      timeout: 120000, // 超时时间, 单位毫秒
      withCredentials: true,
      headers: {
        "Content-Type": contentType || "application/json",
        "website-token": accessToken,
        // "x-tif-uid":"abcd"
      }
    })
      .then(checkStatus)
      .then(checkCode);
  },
  put(url, data, contentType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "put", // 请求协议
      url: url, // 请求的地址
      // data: qs.stringify(data),
      data: JSON.stringify(data), // post 请求的数据
      timeout: 120000, // 超时时间, 单位毫秒
      withCredentials: true,
      headers: {
        "Content-Type": contentType || "application/json",
        "website-token": accessToken
      }
    })
      .then(checkStatus)
      .then(checkCode);
  },
  delete(url, data, contentType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "delete", // 请求协议
      url: url, // 请求的地址
      // data: qs.stringify(data),
      data: JSON.stringify(data), // post 请求的数据
      timeout: 120000, // 超时时间, 单位毫秒
      withCredentials: true,
      headers: {
        "Content-Type": contentType || "application/json",
        "website-token": accessToken
      }
    })
      .then(checkStatus)
      .then(checkCode);
  },
  noStatusGet(url, params, responseType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    const option = {
      method: "get",
      url: url,
      params, // get 请求时带的参数
      timeout: 120000,
      withCredentials: true,
      headers: {
        "website-token": accessToken,
        "Cache-Control": "no-cache",
        Pragma: "no-cache"
      }
    };
    if (responseType) {
      option.responseType = responseType;
    }
    return AjaxPlugin(option)

  },
  noStatusPost(url, data, contentType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "post", // 请求协议
      url: url, // 请求的地址
      // data: qs.stringify(data),
      data: JSON.stringify(data), // post 请求的数据
      timeout: 120000, // 超时时间, 单位毫秒
      withCredentials: true,
      headers: {
        "Content-Type": contentType || "application/json",
        "website-token": accessToken,
        // "x-tif-uid":"abcd"
      }
    })

  },
  noStatusPut(url, data, contentType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "put", // 请求协议
      url: url, // 请求的地址
      // data: qs.stringify(data),
      data: JSON.stringify(data), // post 请求的数据
      timeout: 120000, // 超时时间, 单位毫秒
      withCredentials: true,
      headers: {
        "Content-Type": contentType || "application/json",
        "website-token": accessToken
      }
    })

  },
  noStatusDelete(url, data, contentType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "delete", // 请求协议
      url: url, // 请求的地址
      // data: qs.stringify(data),
      data: JSON.stringify(data), // post 请求的数据
      timeout: 120000, // 超时时间, 单位毫秒
      withCredentials: true,
      headers: {
        "Content-Type": contentType || "application/json",
        "website-token": accessToken
      }
    })

  },
  postUpLoadFile(url, params) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "post", // 请求协议
      url: url, // 请求的地址
      data: params, // post 请求的数据
      timeout: 0, // 超时时间, 单位毫秒
      headers: {
        "Content-Type": "multipart/form-data",
        "website-token": accessToken
      }
    })
      .then(checkStatus)
      .then(checkCode);
  },
  getDownloadFile(url, params, contentType, fileName) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "get", // 请求协议
      url: url, // 请求的地址
      params, // get 请求的数据
      timeout: 120000, // 超时时间, 单位毫秒
      withCredentials: true,
      responseType: "blob",
      headers: {
        "Content-Type": contentType || "application/json",
        "website-token": accessToken
      }
    }).then(res => {
      const BLOB = res.data;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(BLOB);
      fileReader.onload = event => {
        let a = document.createElement("a");
        let contentDisposition = res.headers["content-disposition"];
        if (contentDisposition) {
          a.download = window.decodeURI(
            res.headers["content-disposition"].split("=")[1],
            "UTF-8"
          );
        }
        if (fileName != null) {
          a.download = fileName;
        }
        a.href = event.target.result;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      fileReader.onerror = () => { };
    });
  },
  postDownloadFile(url, data, name, suffix, contentType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "post", // 请求协议
      url: url, // 请求的地址
      data: JSON.stringify(data), // post 请求的数据
      timeout: 120000, // 超时时间, 单位毫秒
      withCredentials: true,
      responseType: "blob",
      headers: {
        "Content-Type": contentType || "application/json",
        "website-token": accessToken
      }
    }).then(res => {
      const BLOB = res.data;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(BLOB);
      fileReader.onload = event => {
        let a = document.createElement("a");
        const suffixName = suffix ? suffix : (suffix = "xls"); // 后缀名
        a.download = `${name}.${suffixName}`;
        a.href = event.target.result;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      fileReader.onerror = () => { };
    });
  },
  postMultipart(url, data, contentType) {
    const accessToken = localStorage.websiteToken ? localStorage.websiteToken : "";
    ajaxInterceptors();
    return AjaxPlugin({
      method: "post", // 请求协议
      url: url, // 请求的地址
      data: data, // post 请求的数据
      timeout: 120000, // 超时时间, 单位毫秒
      withCredentials: true,
      headers: {
        "Content-Type": contentType || "application/json",
        "website-token": accessToken,
        // "x-tif-uid":"abcd"
      }
    })
      .then(checkStatus)
      .then(checkCode);
  },
};
