import appAjax from "../utils/apiRequestType";
import appUrl from "../utils/apiUrl";
import { Base64 } from "js-base64";
import router from "@/router";

// token刷新剩余时间
const leftTime = 1200000;

// function getRefresh(refreshToken) {
//   return appAjax.post(appUrl.defaultUrl + "oa/auth/refresh", {
//     token: refreshToken
//   });
// }
async function authFun(to, from, next) {
  // var param = { "username": "oaadmin", "password": "HngsOA@2022!" };

  if (to.path === '/login') next()
  // 获取缓存信息
  let token = localStorage.getItem("websiteToken");
  let refreshToken = localStorage.getItem("refreshToken");
  let expireTime = localStorage.getItem("expireTime");
  let userInfo = localStorage.getItem("userInfo");
  let currentTime = new Date().getTime();
  if (token && userInfo != "undefined") {
    // 存在token
    // 时间过期，每隔一段时间获取最新token
    // if (expireTime * 1000 - leftTime < currentTime) {
    //   // token过期
    //   const res = await getRefresh(refreshToken);
    //   if (res.status === 200) {
    //     localStorage.setItem("token", res.data.accessToken);
    //     localStorage.setItem("refreshToken", res.data.refreshToken);
    //     const attr = Base64.decode(
    //       res.data.accessToken.split(".")[1]
    //     );
    //     const userAttr = JSON.parse(attr);
    //     localStorage.setItem(
    //       "userInfo",
    //       JSON.stringify(userAttr.userDetails)
    //     );
    //     localStorage.setItem("expireTime", userAttr.exp);
    //   } else {
    //     // 接口获取失败
    //     localStorage.clear();
    //     next({ name: "Login" });
    //     return
    //   }
    // } else {
      // token没过期
      localStorage.websiteToken = token;
      // localStorage.refreshToken = refreshToken;
      // localStorage.expireTime = expireTime;
      // localStorage.userInfo = userInfo;
    // }
    next();
  } else {
    next({ name: "Login" });
    // Vue.$userApp.toast.show({
    //   text: "您还没有登录账号，请登录",
    //   type: "text"
    // });
  }
}

export default authFun;

